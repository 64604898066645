@tailwind base;
@tailwind components;
@tailwind utilities;

.image-bg{
    background-image: url(../src/img/bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-contact{
    background-image: url(../src/img/bgContact.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-chart{
    background-image: url(../src/img/bgContact.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: var(--red);
    transform-origin: 0%;
  }

